// TODO: Use sizes from here
$xxsmall: 9px;
$xsmall: 10px;
$small: 12px;
$medium: 14px;
$large: 16px;
$xlarge: 18px;
$xxlarge: 20px;
$xxxlarge: 23px;
$title: 26px;
$xtitle: 30px;
$large-title: 36px;

// sass-lint:disable no-misspelled-properties
// sass-lint:disable property-sort-order
:export {
  xxsmall: $xxsmall;
  xsmall: $xsmall;
  small: $small;
  medium: $medium;
  large: $large;
  xlarge: $xlarge;
  xxlarge: $xxlarge;
  xxxlarge: $xxxlarge;
  title: $title;
  xtitle: $xtitle;
  largeTitle: $large-title;
}
