$default-vertical-padding: 20px;
$default-horizontal-padding: 30px;
$default-mobile-vertical-padding: 10px;
$default-mobile-horizontal-padding: 5px;
$sidebar-min-width: 250px;
$sidebar-max-width: 260px;
$sidebar-option-height: 35px;
$sidebar-icon-width-and-height: 25px;
$sidebar-icon-image-width-and-height: 20px;
$topbar-footer-height: 36px;
$walkthrough-z-index: 10;
$default-carrousel-height: 200;
$default-carrousel-arrow-size: 50px;
$default-carrousel-legend-size: 10px;
$default-carrousel-legend-active: 14px;
$default-carrousel-legend-touchable: 30px;
$rating-circle-icon-size: 35px;
$rating-circle-mobile-icon-size: 25px;

// sass-lint:disable no-misspelled-properties
// sass-lint:disable property-sort-order
:export {
  defaultCarrouselHeight: $default-carrousel-height;
  defaultCarrouselArrowsize: $default-carrousel-arrow-size;
  defaultCarrouselLegendSize: $default-carrousel-legend-size;
  defaultCarrouselLegendActive: $default-carrousel-legend-active;
  defaultCarrouselLegendTouchable: $default-carrousel-legend-touchable;
}
