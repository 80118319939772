@import '../../scss/variables/mediaQueries';

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formTitle {
  padding-bottom: 15px;
}

.fieldRow {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;

  @media #{$mobile} {
    flex-direction: column;
  }
}
